import $ from 'jquery';

export default (container?: HTMLElement) => {
  // Akkordeon
  $('.accordion__title').click(function () {
    $(this).parent().toggleClass('active');
    $(this).parent().siblings('.accordion__item').removeClass('active');

    $('.accordion__item').each(function () {
      if ($(this).hasClass('active')) {
        $(this).children('.accordion__content').slideDown();
      } else {
        $(this).removeClass('active');
        $(this).children('.accordion__content').slideUp();
      }
    });
  });
};
