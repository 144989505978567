// @ts-ignore
import baguetteBox from 'baguettebox.js';
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

export default () => {
  var wlySwiper = new Swiper('.swiper-container', {
    modules: [Navigation, Pagination, Autoplay],
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    speed: 800,
    slidesPerView: 1,
    spaceBetween: 10,
    breakpoints: {
      601: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      993: {
        slidesPerView: 4,
      },
      1201: {
        slidesPerView: 5,
      },
    },
    grabCursor: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });
};
