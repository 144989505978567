// Import polyfills
import './js/polyfills';
import './js/libraries';
import './js/scripts';

import { RunThemeExtensions } from 'wly-theme-extensions';

import Paginator from '@/js/vue/components/Paginator.vue';
import YoutubePlayer from '@/js/vue/components/YoutubePlayer.vue';

RunThemeExtensions({
  vue: {
    components: {
      'wly-paginator': Paginator,
      'wly-youtube': YoutubePlayer,
    },
    onBootstrap: (app) => {
      // app.use ...
    },
  },
  alpine: {
    components: {
      //
    },
  },
});

// Import styles
// Should be last to override vue component styles more easily
import './sass/style.scss';
